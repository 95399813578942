@use "variables" as *;

body {
  font-family: $main-font;
  line-height: 1.5;
  font-size: 1.125rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  margin: 0 0 1rem;
  color: $yellow;
}

p {
  margin: 0 0 1rem;
}