@use "../global/variables" as *;
@use "../global/mixins" as *;

.feature {
  margin-bottom: 8rem;
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  .feature-content {
    &-item {
      margin-bottom: 2rem;
    
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: $bp-laptop) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
    
    .feature-overview {
      position: sticky;
      align-self: start;
      // Set top dynamically based on height using js to center the feature overview sticky
    }
    
    &.align-left {
      grid-template-areas: "left left left left right right right right right right right right";

      .feature-overview {
        grid-area: left;
      }

      .feature-content {
        grid-area: right;
      }
    }

    &.align-right {
      grid-template-areas: "left left left left left left left left right right right right";
      
      .feature-overview {
        grid-area: right;
      }

      .feature-content {
        grid-area: left;
      }
    }
  }

  @media (min-width: $bp-desktop-lg) {
    &.align-left {
      grid-template-areas: ". left left left right right right right right right right .";
    }

    &.align-right {
      grid-template-areas: ". left left left left left left left right right right .";
    }
  }
}