@use "../global/variables" as *;
@use "../global/mixins" as *;

header {
  z-index: 2;
  position: fixed;
  top: -7rem;
  left: 0;
  right: 0;
  background: rgba($background__blue-dark,0);
  transition: top 0.3s ease-in-out, background 0.3s ease-in-out;
  
  &.show {
    top: 0;
  }

  &.background {
    background: rgba($background__blue-dark,1);
  }

  .navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    // Logo
    .navbar-logo {
      display: inline-block;
      
      a {
        display: block;
        padding: 1rem $site-side-padding;

        .site-logo {
          width: 3rem;
          transition: fill 0.2s ease-in-out;

          &:hover {
            fill: $white;
          }
        }
        
        @media (min-width: $bp-laptop) {
          padding: 0.5rem $site-side-padding;
          
          .site-logo {
            width: 5rem;
          }
        }
      }
    }
  
    // Hamburger Menu
    .navbar-btn {
      background: none;
      border: none;
      position: relative;
      display: block;
      padding: 1rem $site-side-padding;

      @media (min-width: $bp-laptop) {
        padding: 2rem $site-side-padding;
      }
  
      .bar {
        display: block;
        position: relative;
        top: 0;
        margin: 0.5rem 0;
        width: 2rem;
        height: 0.25rem;
        border-radius: 0.25rem;
        background-color: $yellow;
        transition: top 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.2s ease-in-out
      }
  
      &:hover,
      &:focus {
        .bar {
          background-color: $white;
        }
      }
      
    }

    &.navbar-toggled {

      .navbar-logo {
        .site-logo {
          @media (min-width: $bp-phone-md) {
            transition: fill 0.2s ease-in-out 0.6s;
            fill: $background__blue-dark;
          }
        }
      }
      
      .navbar-btn {
        #bar-1 {
          top: 0.75rem;
          transform: rotateZ(-45deg);
        }
  
        #bar-2 {
          opacity: 0;
        }
  
        #bar-3 {
          top: -0.75rem;
          transform: rotateZ(45deg);
        }
      }
    }
  }

  // Nav Menu
  .navbar-menu {
    background-color: $background__blue-dark;
    padding: 0;
    position: fixed;
    inset: 0;
    left: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    z-index: -1;
    transition: left 0.3s ease-in-out 0.3s;

    nav {
      width: calc(100% - 0.5rem);

      @media (min-width: $bp-phone-md) {
        width: calc(100% - 7rem);
      }

      @media (min-width: $bp-laptop) {
        width: 60%;
      }

      ul {
        list-style: none;
        color: $white;
        padding: 0;
        text-align: right;
        margin-right: $site-side-padding;

        @media (min-width: $bp-laptop) {
          margin-right: 10%;
        }
    
        a {
          display: inline-block;
          color: $white;
          @include use-heading-style;
          font-size: 2rem;
          text-decoration: none;
          padding: 1rem;
          opacity: 0;
          transition: background-color 0.2s ease-in-out, opacity 0.3s ease-in-out;

          .underline {
            width: 100%;
            height: 0.375rem;
            background-color: $yellow;
            margin-top: -0.25rem;
            max-width: 0;
            transition: max-width 0.3s ease-out;
  
            @media (min-width: $bp-phone-lg) {
              height: 0.5rem;
              margin-top: -0.5rem;
            }
            
            @media (min-width: $bp-tablet) {
              margin-top: -1rem;
            }
          }
          
          &:hover,
          &:focus {
            .underline {
              max-width: 100%;
            }
          }

          @media (min-width: $bp-phone-lg) {
            font-size: 3rem;
          }

          @media (min-width: $bp-tablet) {
            font-size: 4.5rem;
          }
        }
      }
    }

    .navbar-curtain {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $yellow;
      overflow: hidden;
      transition: width 0.3s ease-in-out;
    }

    &.navbar-toggled {
      // Set to a number bigger than the height of the content
      left: 0;
      transition: left 0.3s ease-in-out;

      ul {
        a {
          opacity: 1;
        }
      }

      .navbar-curtain {
        transition: width 0.3s ease-in-out 0.3s;
        width: 0.5rem;

        @media (min-width: $bp-phone-md) {
          width: 7rem;
        }

        @media (min-width: $bp-laptop) {
          width: 40%;
        }
      }
    }
  }
}