@use "variables" as *;

body {
  margin: 0;
  background-color: $background__blue;
  color: $white;

  &.stop-scroll {
    overflow: hidden;
  }
}

.site-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-x: clip;
}

main {
  min-height: 100vh;
  position: relative;
  flex-grow: 1;
  display: grid;
  grid-template-areas: ". content .";
  grid-template-columns: minmax($site-side-padding, 1fr) minmax(0, $max-width) minmax($site-side-padding, 1fr);

  section.page {
    grid-area: content;
  }
}

a {
  color: $yellow;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $white;
    text-decoration: underline;
  }
}

button {
  cursor: pointer;
}

img, 
picture,
video {
  display: block;
}

figure {
  margin: 0;
}

figcaption {
  text-align: center;
  padding: 0.5rem 1rem;
  color: rgba($text__light-blue, 0.5);
}