@use "../global/variables" as *;
@use "../global/mixins" as *;

.page-project {

  h1 {
    font-size: 2.25rem;
    
    @media (min-width: $bp-laptop) {
      font-size: 3rem;
    }

    @media (min-width: $bp-desktop) {
      font-size: 4.5rem;
    }
  }

  h2 {
    font-size: 2.25rem;
    margin-bottom: 2.25rem;

    @media (min-width: $bp-laptop) {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }

  h3 {
    font-size: 1.5rem;

    @media (min-width: $bp-desktop) {
      font-size: 2.25rem;
    }
  }

  .content-wrapper > section {
    margin: 10rem 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .project-header {    
    .project-year {
      @include use-heading-style;
      font-size: 1.5rem;
      margin-bottom: 6.25rem;

      @media (min-width: $bp-desktop) {
        font-size: 2rem;
      }
    }

    .project-name {
      margin-bottom: 0.25rem;
    }

    .project-subtitle {
      font-family: $heading-font;
      
      @media (min-width: $bp-desktop) {
        font-size: 1.5rem;
      }
    }
    
    
    .featured-image {
      @include use-full-width(8rem);

      @media (min-width: $max-width) {
        @include disable-full-width(8rem);
        @include use-round-corners;
      }
    }
  }

  .project-details-section {
    @media (min-width: $bp-laptop) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: ". left left left left left left right right right right .";
      gap: 2rem;

      .left-column {
        grid-area: left;
      }

      .right-column {
        grid-area: right;
      }

      .left-column > section,
      .right-column > section {
        margin-bottom: 2rem;
      }
    }
    
    a {
      color: $white;

      &:hover,
      &:focus {
        color: $yellow;
      }
    }

    @media (min-width: $bp-desktop-lg) {
      grid-template-areas: ". left left left left left left . right right right .";
    }
  }

  .project-collaborators-section {
    ul {
      padding: 0;
      margin: 0 0 1rem;
      list-style: none;
    }

    .collaborator-link {
      .link-icon {
        font-size: 0.875rem;
        margin-left: 0.25rem;
      }
    }
  }

  .project-links-section {
    .project-link {
      padding: 1rem 0;
      display: grid;
      grid-template-columns: 3rem 1fr;
      gap: 0.5rem;
      line-height: 1;
      align-items: center;

      .custom-icon {
        font-size: 2rem;
      }

      &:first-of-type {
        padding-top: 0;
      }
    }
  }


  .project-features-section {
    
    .project-features-header {
      text-align: center;
    }
    
    .coming-soon {
      text-align: center;
    }
  }

  .other-projects-section {
    .other-projects-header {
      text-align: center;
    }

    .project-card {
      &:first-of-type {
        margin-top: 0;
      }

      .project-link {
        text-decoration: none;
      }

      .project-subtitle {
        color: $white;
      }

      @media (min-width: $bp-laptop) {
        display: block;
      }
    }

    @media (min-width: $bp-laptop) {
      display: grid;
      grid-template-areas: 
        "heading heading"
        "left right"
      ;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 2rem;

      .other-projects-header {
        grid-area: heading;
      }

      .align-left {
        grid-area: left;
      }

      .align-right {
        grid-area: right;
      }

      .project-card {
        margin: 0;
      }
    }
  }
}