@use "./variables" as *;

@mixin use-heading-style {
  font-family: $heading-font;
  font-weight: 700;
}

@mixin use-full-width($vertical-padding: 0) {
  width: 100vw;
  margin: $vertical-padding calc(calc(100% - 100vw)/2);
}

@mixin disable-full-width($vertical-padding: 0) {
  margin: $vertical-padding 0;
  width: 100%;
}

@mixin use-round-corners($radius: 1rem) {
  overflow: hidden;
  border-radius: $radius;
}