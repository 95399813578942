@use "../global/variables" as *;
@use "../global/mixins" as *;

.project-card {
  margin: 4rem 0;

  .project-text {
    .project-title {
      font-size: 1.5rem;
      margin-bottom: 0.125rem;

      @media (min-width: $bp-tablet) {
        font-size: 2.25rem;
      }
    }

    .project-subtitle {
      font-size: 0.875rem;
      font-family: $heading-font;

      @media (min-width: $bp-laptop) {
        font-size: 1.125rem;
      }
    }

    .project-link {
      display: block;
      width: 11rem;
      position: relative;
      height: 4rem;
      padding: 1rem;
      margin: 0 auto;
      background-color: $background__blue-dark;
      border-radius: 4rem;
      overflow: hidden;
      @include use-heading-style;
      line-height: 1;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      .link-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 9rem;
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
      }

      @media (min-width: $bp-laptop) {
        margin: 0;
        max-width: 4rem;
        transition: max-width 0.3s ease-in-out 0.2s;
        
        .link-content {
          right: 1.4rem;
          transition: right 0.3s ease-in-out 0.3s;
        }
        
        .link-text {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        &:hover,
        &:focus  {
          max-width: 11rem;
          transition: max-width 0.3s ease-in-out;

          .link-content {
            right: 1rem;
            transition: right 0.3s ease-in-out;
          }

          .link-text {
            opacity: 1;
            transition: opacity 0.3s ease-in-out 0.2s;
          }
        }
      }
    }
  }

  .project-image {    
    @include use-full-width;
    margin-bottom: 2rem;
  }
  
  @media (min-width: $bp-laptop) {
    display: grid;
    align-items: center;
    
    .project-text {
      grid-area: text;
    }
    
    .project-image {
      grid-area: thumbnail;
      @include disable-full-width;
      @include use-round-corners;
    }
    
    &.align-left {
      grid-template-columns: 4fr 1fr 7fr;
      grid-template-areas: 
        "text . thumbnail"
      ;
    }
    
    &.align-right {
      grid-template-columns: 7fr 1fr 4fr;
      grid-template-areas: 
        "thumbnail . text"
      ;
    }
  }
}