@use "../global/variables" as *;
@use "../global/mixins" as *;

.feature-content-item {
  scroll-margin: 10rem;

  > * {
    position: relative;
    @include use-round-corners;
  } 

  .video-content {
    @include use-full-width;
    border-radius: 0;
  
    @media (min-width: $bp-laptop) {
      @include disable-full-width;
      @include use-round-corners;
    }
  }

  .code-content {
    position: relative;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;

    pre {
      margin: 0;
      padding: 1rem 1rem 4.5rem;

      @media (min-width: $bp-laptop) {
        code > span {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    &.code-closed {
      max-height: 20rem;  

      .code-fog {
        opacity: 1;
        visibility: visible;
      }
    }
    
    .code-fog {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(rgba($background__blue-dark, 0), $black);
      height: 7rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    }

    .code-button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 1rem;
      background-color: $background__blue-dark;
      color: $yellow;
      border: none;
      border-top: 0.125rem solid $background__blue;
      @include use-heading-style;
      font-size: 1rem;
      display: flex; gap: 1rem;
      align-items: center;

      &:hover {
        color: $white;
      }
    }
  }
}
