
// Colours
$yellow: #EAB454;
$background__blue: #222E3C;
$background__blue-dark: #121A24; 
$text__light-blue: #B8D7FF;
$white: #FFFFFF;
$black: #000000;


// Fonts
$main-font: "Nunito", sans-serif;
$heading-font: "Montserrat", sans-serif;

// Layout
$site-side-padding: 2rem;

// Breakpoints
$bp-phone-md: 23.5rem;  // 376px
$bp-phone-lg: 30rem;    // 480px
$bp-tablet: 35rem;      // 560px
$bp-laptop: 56.25rem;   // 900px
$bp-desktop: 75rem;     // 1200px
$bp-desktop-lg: 90rem;  // 1440px

// Max width for large screens
$max-width: 100rem;      // 1600px
$half-width: 50rem;